import { SVGProps, Ref, forwardRef } from 'react'

const SvgExecutor = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    width="1.5rem"
    height="1.5rem"
    fill="none"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M4.326 9.133a19.81 19.81 0 0 0 7.526-7.526.56.56 0 0 1 .727.054l3.508 3.507v.001a.57.57 0 0 1 .057.735 18.84 18.84 0 0 0-7.53 7.522.56.56 0 0 1-.725-.057L4.38 9.86a.56.56 0 0 1-.054-.727Zm7.858-9.135a2.06 2.06 0 0 0-1.454.6.75.75 0 0 0-.127.168 18.31 18.31 0 0 1-7.118 7.118.75.75 0 0 0-.167.126 2.06 2.06 0 0 0 0 2.91l3.51 3.51.002.001a2.06 2.06 0 0 0 2.909 0 .752.752 0 0 0 .131-.176c.432-.806.926-1.573 1.475-2.295l8.2 8.704.016.017a2.06 2.06 0 0 0 2.91 0l.93-.93v-.001a2.06 2.06 0 0 0-.016-2.926l-8.7-8.197a17.342 17.342 0 0 1 2.288-1.466.75.75 0 0 0 .178-.133 2.07 2.07 0 0 0 0-2.916l-.002-.002L13.64.6V.599a2.06 2.06 0 0 0-1.455-.601ZM13.52 9.59a17.34 17.34 0 0 0-1.21 1.207l8.317 8.827a.56.56 0 0 0 .784-.006l.929-.929a.56.56 0 0 0 .006-.784L13.52 9.591ZM2.159 19.159A2.25 2.25 0 0 1 3.75 18.5h7.5a2.25 2.25 0 0 1 2.25 2.25v1.75h.75a.75.75 0 0 1 0 1.5H.75a.75.75 0 0 1 0-1.5h.75v-1.75c0-.597.237-1.169.659-1.591ZM3 22.5h9v-1.75a.75.75 0 0 0-.75-.75h-7.5a.75.75 0 0 0-.75.75v1.75Z"
      clipRule="evenodd"
    />
  </svg>
)

const ForwardRef = forwardRef(SvgExecutor)
export default ForwardRef
